<template>
  <div class="success">
    <div class="container-lg">
      <div class="row mb-3">
        <div class="col-3 col-sm-1 mx-auto">
          <img
            src="@/assets/img/succesicon.png"
            class="img-fluid"
            alt="success icon"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 mx-auto">
          <h1 class="text-center b-700">Payment Successful!</h1>
          <p class="text-center">Thank you for registering for INCASE 2023.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "success",
};
</script>

<style lang="scss" scoped>
.success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 160px);
}
</style>
